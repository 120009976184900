<template>
  <div>
    <div :id="id"></div>
  </div>
</template>

<script>
import * as G2 from "@antv/g2";
export default {
  name: 'areaChart',
  props: {
      id: {
      //图表id
      type: String,
    },
    data: Array,
    total: Number,
    color: {
      //颜色
      type: Array,
      default: () => {
        return [
          'rgb(245,189,99)',
          'rgb(66,195,104)',
          'rgb(80,175,252)',
          '#205395',
        ]
      },
     }
  },
  name: "spectaculars",
  data() {
    return {
      chart: null,
    };
  },
  methods: {
    loadData() {
      const data = this.data;
      // Step 1: 创建 Chart 对象
      const chart = new G2.Chart({
        container: this.id, // 指定图表容器 ID
        autoFit: true,
        height: 200,
        // 指定图表高度
      });
      // Step 2: 载入数据源
      chart.data(data);
      chart.scale('Val', {
        formatter: (val) => {
            return val;
        },
        });
        
      chart.coordinate("theta", {
        radius: 0.6,
        innerRadius: 0.7,
      });
      chart.legend(false)
      chart.tooltip({
        showMarkers: false,
        stroke: "#fff"
        }
      );
  
      chart
        .interval()
        .adjust("stack")
        .position("Val")
        .color('Name', this.color)
        
        
      chart
        .annotation()
        .text({
          position: ["50%", "50%"],
          content: "总计",
          style: {
            fontSize: 14,
            fill: "#333333",
            textAlign: "center",
          },
          offsetY: -10,
        })
        .text({
          position: ["50%", "50%"],
          content: (data) => {
              return `${this.total}${this.id == 'pie2' ? '辆': '人'}`;
            },
          style: {
            fontSize: 16,
            fill: "#333333",
            textAlign: "center",
          },
          offsetY: 10,
        })
      chart.interaction("element-active");
      chart.render();
      this.chart = chart
    },
  },
   watch: {
    data(val) {
      //数据更改重新渲染图表
      this.chart.changeData(val);
      this.chart.render();
    },
  },
  mounted() {
    this.$nextTick(()=>{
      this.loadData();
    })
  },
  beforeCreate() {},
  beforeDestroy() {},
};
</script>

<style scoped>
</style>