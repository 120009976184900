<template>
  <div class="mapCmpt">
    <iframe id="mapIframe" width="100%" height="100%" src="http://components.sinoiov.cn/index.html?k=5BE83766-5E02-4744-BA66-6227BC2C1CDB" frameborder="0"></iframe>
  </div>
</template>

<script>
export default {
  propr: {
    path: Array
  },
  data() {
    return {
      data: null,
      arr: [{
        "lat": 28.611087200447557,
        "lng": 115.92907059139796,
        "gpsTime": "2021-06-09 14:04:17",
        "direction": 0,
        "gpsSpeed": -1.0,
        "posType": 2,
        "address": null,
        "mileage": 0.0
      }, {
        "lat": 28.60789094943157,
        "lng": 115.92896795933349,
        "gpsTime": "2021-06-09 14:05:28",
        "direction": 0,
        "gpsSpeed": -1.0,
        "posType": 2,
        "address": null,
        "mileage": 0.0
      }, {
        "lat": 28.606498,
        "lng": 115.928551,
        "gpsTime": "2021-06-09 14:05:40",
        "direction": 177,
        "gpsSpeed": 33.0,
        "posType": 1,
        "address": null,
        "mileage": 0.0
      }, {
        "lat": 28.605444,
        "lng": 115.928631,
        "gpsTime": "2021-06-09 14:05:50",
        "direction": 177,
        "gpsSpeed": 41.0,
        "posType": 1,
        "address": null,
        "mileage": 0.0
      }, {
        "lat": 28.604387,
        "lng": 115.928693,
        "gpsTime": "2021-06-09 14:06:00",
        "direction": 179,
        "gpsSpeed": 40.0,
        "posType": 1,
        "address": null,
        "mileage": 0.0
      }, {
        "lat": 28.60356,
        "lng": 115.928667,
        "gpsTime": "2021-06-09 14:06:09",
        "direction": 200,
        "gpsSpeed": 32.0,
        "posType": 1,
        "address": null,
        "mileage": 0.0
      }, {
        "lat": 28.603393,
        "lng": 115.928498,
        "gpsTime": "2021-06-09 14:06:12",
        "direction": 223,
        "gpsSpeed": 30.0,
        "posType": 1,
        "address": null,
        "mileage": 0.0
      }, {
        "lat": 28.603336,
        "lng": 115.928249,
        "gpsTime": "2021-06-09 14:06:15",
        "direction": 247,
        "gpsSpeed": 29.0,
        "posType": 1,
        "address": null,
        "mileage": 0.0
      }, {
        "lat": 28.603402,
        "lng": 115.927769,
        "gpsTime": "2021-06-09 14:06:20",
        "direction": 273,
        "gpsSpeed": 34.0,
        "posType": 1,
        "address": null,
        "mileage": 0.0
      }, {
        "lat": 28.603789,
        "lng": 115.926827,
        "gpsTime": "2021-06-09 14:06:28",
        "direction": 295,
        "gpsSpeed": 39.0,
        "posType": 1,
        "address": null,
        "mileage": 0.0
      }, {
        "lat": 28.604191,
        "lng": 115.925556,
        "gpsTime": "2021-06-09 14:06:38",
        "direction": 280,
        "gpsSpeed": 48.0,
        "posType": 1,
        "address": null,
        "mileage": 0.0
      }, {
        "lat": 28.604676,
        "lng": 115.924462,
        "gpsTime": "2021-06-09 14:06:46",
        "direction": 300,
        "gpsSpeed": 47.0,
        "posType": 1,
        "address": null,
        "mileage": 0.0
      }, {
        "lat": 28.605042,
        "lng": 115.924142,
        "gpsTime": "2021-06-09 14:06:50",
        "direction": 324,
        "gpsSpeed": 43.0,
        "posType": 1,
        "address": null,
        "mileage": 0.0
      }, {
        "lat": 28.605504,
        "lng": 115.924089,
        "gpsTime": "2021-06-09 14:06:55",
        "direction": 351,
        "gpsSpeed": 32.0,
        "posType": 1,
        "address": null,
        "mileage": 0.0
      }, {
        "lat": 28.605562,
        "lng": 115.924702,
        "gpsTime": "2021-06-09 14:07:04",
        "direction": 114,
        "gpsSpeed": 17.0,
        "posType": 1,
        "address": null,
        "mileage": 0.0
      }, {
        "lat": 28.605191,
        "lng": 115.925307,
        "gpsTime": "2021-06-09 14:07:08",
        "direction": 138,
        "gpsSpeed": 34.0,
        "posType": 1,
        "address": null,
        "mileage": 0.0
      }, {
        "lat": 28.604787,
        "lng": 115.925618,
        "gpsTime": "2021-06-09 14:07:13",
        "direction": 162,
        "gpsSpeed": 31.0,
        "posType": 1,
        "address": null,
        "mileage": 0.0
      }, {
        "lat": 28.604533,
        "lng": 115.925556,
        "gpsTime": "2021-06-09 14:07:16",
        "direction": 194,
        "gpsSpeed": 27.0,
        "posType": 1,
        "address": null,
        "mileage": 0.0
      }, {
        "lat": 28.604407,
        "lng": 115.92544,
        "gpsTime": "2021-06-09 14:07:18",
        "direction": 221,
        "gpsSpeed": 26.0,
        "posType": 1,
        "address": null,
        "mileage": 0.0
      }, {
        "lat": 28.604269,
        "lng": 115.925191,
        "gpsTime": "2021-06-09 14:07:22",
        "direction": 245,
        "gpsSpeed": 23.0,
        "posType": 1,
        "address": null,
        "mileage": 0.0
      }, {
        "lat": 28.604251,
        "lng": 115.924836,
        "gpsTime": "2021-06-09 14:07:27",
        "direction": 268,
        "gpsSpeed": 26.0,
        "posType": 1,
        "address": null,
        "mileage": 0.0
      }, {
        "lat": 28.604518,
        "lng": 115.924142,
        "gpsTime": "2021-06-09 14:07:34",
        "direction": 290,
        "gpsSpeed": 39.0,
        "posType": 1,
        "address": null,
        "mileage": 0.0
      }, {
        "lat": 28.605151,
        "lng": 115.922818,
        "gpsTime": "2021-06-09 14:07:44",
        "direction": 300,
        "gpsSpeed": 55.0,
        "posType": 1,
        "address": null,
        "mileage": 0.0
      }, {
        "lat": 28.6061,
        "lng": 115.921307,
        "gpsTime": "2021-06-09 14:07:54",
        "direction": 304,
        "gpsSpeed": 69.0,
        "posType": 1,
        "address": null,
        "mileage": 0.0
      }, {
        "lat": 28.607158,
        "lng": 115.919707,
        "gpsTime": "2021-06-09 14:08:04",
        "direction": 302,
        "gpsSpeed": 74.0,
        "posType": 1,
        "address": null,
        "mileage": 0.0
      }, {
        "lat": 28.608089,
        "lng": 115.918098,
        "gpsTime": "2021-06-09 14:08:14",
        "direction": 301,
        "gpsSpeed": 58.0,
        "posType": 1,
        "address": null,
        "mileage": 0.0
      }, {
        "lat": 28.608956,
        "lng": 115.916569,
        "gpsTime": "2021-06-09 14:08:24",
        "direction": 304,
        "gpsSpeed": 70.0,
        "posType": 1,
        "address": null,
        "mileage": 0.0
      }, {
        "lat": 28.610053,
        "lng": 115.914791,
        "gpsTime": "2021-06-09 14:08:34",
        "direction": 305,
        "gpsSpeed": 77.0,
        "posType": 1,
        "address": null,
        "mileage": 0.0
      }, {
        "lat": 28.611007,
        "lng": 115.912942,
        "gpsTime": "2021-06-09 14:08:44",
        "direction": 297,
        "gpsSpeed": 72.0,
        "posType": 1,
        "address": null,
        "mileage": 0.0
      }, {
        "lat": 28.611682,
        "lng": 115.910987,
        "gpsTime": "2021-06-09 14:08:54",
        "direction": 287,
        "gpsSpeed": 73.0,
        "posType": 1,
        "address": null,
        "mileage": 0.0
      }, {
        "lat": 28.612044,
        "lng": 115.908791,
        "gpsTime": "2021-06-09 14:09:04",
        "direction": 276,
        "gpsSpeed": 78.0,
        "posType": 1,
        "address": null,
        "mileage": 0.0
      }, {
        "lat": 28.612029,
        "lng": 115.906507,
        "gpsTime": "2021-06-09 14:09:14",
        "direction": 267,
        "gpsSpeed": 75.0,
        "posType": 1,
        "address": null,
        "mileage": 0.0
      }, {
        "lat": 28.611922,
        "lng": 115.904542,
        "gpsTime": "2021-06-09 14:09:24",
        "direction": 267,
        "gpsSpeed": 69.0,
        "posType": 1,
        "address": null,
        "mileage": 0.0
      }, {
        "lat": 28.611816,
        "lng": 115.902391,
        "gpsTime": "2021-06-09 14:09:34",
        "direction": 269,
        "gpsSpeed": 78.0,
        "posType": 1,
        "address": null,
        "mileage": 0.0
      }, {
        "lat": 28.611844,
        "lng": 115.900364,
        "gpsTime": "2021-06-09 14:09:44",
        "direction": 270,
        "gpsSpeed": 68.0,
        "posType": 1,
        "address": null,
        "mileage": 0.0
      }, {
        "lat": 28.611878,
        "lng": 115.898258,
        "gpsTime": "2021-06-09 14:09:54",
        "direction": 270,
        "gpsSpeed": 75.0,
        "posType": 1,
        "address": null,
        "mileage": 0.0
      }, {
        "lat": 28.611898,
        "lng": 115.895964,
        "gpsTime": "2021-06-09 14:10:04",
        "direction": 269,
        "gpsSpeed": 80.0,
        "posType": 1,
        "address": null,
        "mileage": 0.0
      }, {
        "lat": 28.611942,
        "lng": 115.893698,
        "gpsTime": "2021-06-09 14:10:14",
        "direction": 269,
        "gpsSpeed": 78.0,
        "posType": 1,
        "address": null,
        "mileage": 0.0
      }, {
        "lat": 28.611991,
        "lng": 115.891529,
        "gpsTime": "2021-06-09 14:10:24",
        "direction": 269,
        "gpsSpeed": 78.0,
        "posType": 1,
        "address": null,
        "mileage": 0.0
      }, {
        "lat": 28.61202,
        "lng": 115.889289,
        "gpsTime": "2021-06-09 14:10:34",
        "direction": 269,
        "gpsSpeed": 76.0,
        "posType": 1,
        "address": null,
        "mileage": 0.0
      }, {
        "lat": 28.611996,
        "lng": 115.887236,
        "gpsTime": "2021-06-09 14:10:44",
        "direction": 270,
        "gpsSpeed": 67.0,
        "posType": 1,
        "address": null,
        "mileage": 0.0
      }, {
        "lat": 28.612049,
        "lng": 115.885369,
        "gpsTime": "2021-06-09 14:10:54",
        "direction": 273,
        "gpsSpeed": 68.0,
        "posType": 1,
        "address": null,
        "mileage": 0.0
      }, {
        "lat": 28.61226,
        "lng": 115.883289,
        "gpsTime": "2021-06-09 14:11:04",
        "direction": 276,
        "gpsSpeed": 71.0,
        "posType": 1,
        "address": null,
        "mileage": 0.0
      }, {
        "lat": 28.612524,
        "lng": 115.881333,
        "gpsTime": "2021-06-09 14:11:14",
        "direction": 279,
        "gpsSpeed": 69.0,
        "posType": 1,
        "address": null,
        "mileage": 0.0
      }, {
        "lat": 28.612829,
        "lng": 115.879298,
        "gpsTime": "2021-06-09 14:11:24",
        "direction": 279,
        "gpsSpeed": 74.0,
        "posType": 1,
        "address": null,
        "mileage": 0.0
      }, {
        "lat": 28.613209,
        "lng": 115.877209,
        "gpsTime": "2021-06-09 14:11:34",
        "direction": 280,
        "gpsSpeed": 74.0,
        "posType": 1,
        "address": null,
        "mileage": 0.0
      }, {
        "lat": 28.613551,
        "lng": 115.875147,
        "gpsTime": "2021-06-09 14:11:44",
        "direction": 278,
        "gpsSpeed": 71.0,
        "posType": 1,
        "address": null,
        "mileage": 0.0
      }, {
        "lat": 28.613827,
        "lng": 115.873227,
        "gpsTime": "2021-06-09 14:11:54",
        "direction": 278,
        "gpsSpeed": 64.0,
        "posType": 1,
        "address": null,
        "mileage": 0.0
      }, {
        "lat": 28.614058,
        "lng": 115.871351,
        "gpsTime": "2021-06-09 14:12:04",
        "direction": 278,
        "gpsSpeed": 70.0,
        "posType": 1,
        "address": null,
        "mileage": 0.0
      }, {
        "lat": 28.614384,
        "lng": 115.869316,
        "gpsTime": "2021-06-09 14:12:14",
        "direction": 284,
        "gpsSpeed": 71.0,
        "posType": 1,
        "address": null,
        "mileage": 0.0
      }, {
        "lat": 28.614962,
        "lng": 115.867431,
        "gpsTime": "2021-06-09 14:12:24",
        "direction": 286,
        "gpsSpeed": 69.0,
        "posType": 1,
        "address": null,
        "mileage": 0.0
      }, {
        "lat": 28.615487,
        "lng": 115.865796,
        "gpsTime": "2021-06-09 14:12:34",
        "direction": 290,
        "gpsSpeed": 57.0,
        "posType": 1,
        "address": null,
        "mileage": 0.0
      }, {
        "lat": 28.615907,
        "lng": 115.864276,
        "gpsTime": "2021-06-09 14:12:44",
        "direction": 285,
        "gpsSpeed": 51.0,
        "posType": 1,
        "address": null,
        "mileage": 0.0
      }, {
        "lat": 28.61624,
        "lng": 115.863262,
        "gpsTime": "2021-06-09 14:12:54",
        "direction": 295,
        "gpsSpeed": 36.0,
        "posType": 1,
        "address": null,
        "mileage": 0.0
      }, {
        "lat": 28.616638,
        "lng": 115.862747,
        "gpsTime": "2021-06-09 14:13:00",
        "direction": 315,
        "gpsSpeed": 43.0,
        "posType": 1,
        "address": null,
        "mileage": 0.0
      }, {
        "lat": 28.617578,
        "lng": 115.861884,
        "gpsTime": "2021-06-09 14:13:10",
        "direction": 320,
        "gpsSpeed": 49.0,
        "posType": 1,
        "address": null,
        "mileage": 0.0
      }, {
        "lat": 28.618233,
        "lng": 115.861529,
        "gpsTime": "2021-06-09 14:13:16",
        "direction": 340,
        "gpsSpeed": 44.0,
        "posType": 1,
        "address": null,
        "mileage": 0.0
      }, {
        "lat": 28.618689,
        "lng": 115.861538,
        "gpsTime": "2021-06-09 14:13:20",
        "direction": 6,
        "gpsSpeed": 42.0,
        "posType": 1,
        "address": null,
        "mileage": 0.0
      }, {
        "lat": 28.61934,
        "lng": 115.861849,
        "gpsTime": "2021-06-09 14:13:26",
        "direction": 29,
        "gpsSpeed": 48.0,
        "posType": 1,
        "address": null,
        "mileage": 0.0
      }, {
        "lat": 28.620389,
        "lng": 115.862631,
        "gpsTime": "2021-06-09 14:13:36",
        "direction": 35,
        "gpsSpeed": 47.0,
        "posType": 1,
        "address": null,
        "mileage": 0.0
      }, {
        "lat": 28.621184,
        "lng": 115.863298,
        "gpsTime": "2021-06-09 14:13:46",
        "direction": 40,
        "gpsSpeed": 32.0,
        "posType": 1,
        "address": null,
        "mileage": 0.0
      }, {
        "lat": 28.621436,
        "lng": 115.863627,
        "gpsTime": "2021-06-09 14:13:59",
        "direction": 53,
        "gpsSpeed": 0.0,
        "posType": 1,
        "address": null,
        "mileage": 0.0
      }, {
        "lat": 28.621709,
        "lng": 115.863982,
        "gpsTime": "2021-06-09 14:14:26",
        "direction": 45,
        "gpsSpeed": 27.0,
        "posType": 1,
        "address": null,
        "mileage": 0.0
      }, {
        "lat": 28.622318,
        "lng": 115.864844,
        "gpsTime": "2021-06-09 14:14:36",
        "direction": 55,
        "gpsSpeed": 43.0,
        "posType": 1,
        "address": null,
        "mileage": 0.0
      }, {
        "lat": 28.622933,
        "lng": 115.865938,
        "gpsTime": "2021-06-09 14:14:46",
        "direction": 58,
        "gpsSpeed": 48.0,
        "posType": 1,
        "address": null,
        "mileage": 0.0
      }, {
        "lat": 28.623507,
        "lng": 115.86704,
        "gpsTime": "2021-06-09 14:14:56",
        "direction": 61,
        "gpsSpeed": 39.0,
        "posType": 1,
        "address": null,
        "mileage": 0.0
      }, {
        "lat": 28.623893,
        "lng": 115.867644,
        "gpsTime": "2021-06-09 14:15:06",
        "direction": 51,
        "gpsSpeed": 14.0,
        "posType": 1,
        "address": null,
        "mileage": 0.0
      }, {
        "lat": 28.623913,
        "lng": 115.86768,
        "gpsTime": "2021-06-09 14:15:12",
        "direction": 52,
        "gpsSpeed": 0.0,
        "posType": 1,
        "address": null,
        "mileage": 0.0
      }, {
        "lat": 28.62402,
        "lng": 115.867707,
        "gpsTime": "2021-06-09 14:16:06",
        "direction": 51,
        "gpsSpeed": 18.0,
        "posType": 1,
        "address": null,
        "mileage": 0.0
      }, {
        "lat": 28.624447,
        "lng": 115.868364,
        "gpsTime": "2021-06-09 14:16:16",
        "direction": 50,
        "gpsSpeed": 35.0,
        "posType": 1,
        "address": null,
        "mileage": 0.0
      }, {
        "lat": 28.625182,
        "lng": 115.869227,
        "gpsTime": "2021-06-09 14:16:26",
        "direction": 44,
        "gpsSpeed": 42.0,
        "posType": 1,
        "address": null,
        "mileage": 0.0
      }, {
        "lat": 28.625989,
        "lng": 115.870018,
        "gpsTime": "2021-06-09 14:16:36",
        "direction": 36,
        "gpsSpeed": 41.0,
        "posType": 1,
        "address": null,
        "mileage": 0.0
      }, {
        "lat": 28.626827,
        "lng": 115.870676,
        "gpsTime": "2021-06-09 14:16:46",
        "direction": 31,
        "gpsSpeed": 39.0,
        "posType": 1,
        "address": null,
        "mileage": 0.0
      }, {
        "lat": 28.6276,
        "lng": 115.871227,
        "gpsTime": "2021-06-09 14:16:56",
        "direction": 31,
        "gpsSpeed": 38.0,
        "posType": 1,
        "address": null,
        "mileage": 0.0
      }, {
        "lat": 28.628247,
        "lng": 115.871556,
        "gpsTime": "2021-06-09 14:17:06",
        "direction": 27,
        "gpsSpeed": 9.0,
        "posType": 1,
        "address": null,
        "mileage": 0.0
      }, {
        "lat": 28.628211,
        "lng": 115.871538,
        "gpsTime": "2021-06-09 14:17:12",
        "direction": 35,
        "gpsSpeed": 0.0,
        "posType": 1,
        "address": null,
        "mileage": 0.0
      }, {
        "lat": 28.628344,
        "lng": 115.871538,
        "gpsTime": "2021-06-09 14:18:24",
        "direction": 336,
        "gpsSpeed": 15.0,
        "posType": 1,
        "address": null,
        "mileage": 0.0
      }, {
        "lat": 28.628482,
        "lng": 115.871369,
        "gpsTime": "2021-06-09 14:18:27",
        "direction": 314,
        "gpsSpeed": 23.0,
        "posType": 1,
        "address": null,
        "mileage": 0.0
      }, {
        "lat": 28.628911,
        "lng": 115.870649,
        "gpsTime": "2021-06-09 14:18:37",
        "direction": 303,
        "gpsSpeed": 26.0,
        "posType": 1,
        "address": null,
        "mileage": 0.0
      }, {
        "lat": 28.629344,
        "lng": 115.869973,
        "gpsTime": "2021-06-09 14:18:47",
        "direction": 298,
        "gpsSpeed": 30.0,
        "posType": 1,
        "address": null,
        "mileage": 0.0
      }, {
        "lat": 28.629744,
        "lng": 115.869236,
        "gpsTime": "2021-06-09 14:18:57",
        "direction": 295,
        "gpsSpeed": 21.0,
        "posType": 1,
        "address": null,
        "mileage": 0.0
      }, {
        "lat": 28.629887,
        "lng": 115.868827,
        "gpsTime": "2021-06-09 14:19:07",
        "direction": 292,
        "gpsSpeed": 11.0,
        "posType": 1,
        "address": null,
        "mileage": 0.0
      }, {
        "lat": 28.629858,
        "lng": 115.868782,
        "gpsTime": "2021-06-09 14:19:14",
        "direction": 307,
        "gpsSpeed": 0.0,
        "posType": 1,
        "address": null,
        "mileage": 0.0
      }, {
        "lat": 28.629927,
        "lng": 115.86864,
        "gpsTime": "2021-06-09 14:19:23",
        "direction": 282,
        "gpsSpeed": 16.0,
        "posType": 1,
        "address": null,
        "mileage": 0.0
      }, {
        "lat": 28.630367,
        "lng": 115.867947,
        "gpsTime": "2021-06-09 14:19:33",
        "direction": 299,
        "gpsSpeed": 31.0,
        "posType": 1,
        "address": null,
        "mileage": 0.0
      }, {
        "lat": 28.630713,
        "lng": 115.867138,
        "gpsTime": "2021-06-09 14:19:43",
        "direction": 293,
        "gpsSpeed": 29.0,
        "posType": 1,
        "address": null,
        "mileage": 0.0
      }, {
        "lat": 28.631213,
        "lng": 115.866373,
        "gpsTime": "2021-06-09 14:19:53",
        "direction": 304,
        "gpsSpeed": 31.0,
        "posType": 1,
        "address": null,
        "mileage": 0.0
      }, {
        "lat": 28.631736,
        "lng": 115.865476,
        "gpsTime": "2021-06-09 14:20:03",
        "direction": 300,
        "gpsSpeed": 41.0,
        "posType": 1,
        "address": null,
        "mileage": 0.0
      }, {
        "lat": 28.632276,
        "lng": 115.864418,
        "gpsTime": "2021-06-09 14:20:13",
        "direction": 299,
        "gpsSpeed": 40.0,
        "posType": 1,
        "address": null,
        "mileage": 0.0
      }, {
        "lat": 28.63272,
        "lng": 115.863511,
        "gpsTime": "2021-06-09 14:20:23",
        "direction": 301,
        "gpsSpeed": 30.0,
        "posType": 1,
        "address": null,
        "mileage": 0.0
      }, {
        "lat": 28.632909,
        "lng": 115.862996,
        "gpsTime": "2021-06-09 14:20:33",
        "direction": 267,
        "gpsSpeed": 11.0,
        "posType": 1,
        "address": null,
        "mileage": 0.0
      }, {
        "lat": 28.632787,
        "lng": 115.86304,
        "gpsTime": "2021-06-09 14:20:42",
        "direction": 142,
        "gpsSpeed": 3.0,
        "posType": 1,
        "address": null,
        "mileage": 0.0
      }, {
        "lat": 28.632762,
        "lng": 115.86312,
        "gpsTime": "2021-06-09 14:57:33",
        "direction": 201,
        "gpsSpeed": 0.0,
        "posType": 1,
        "address": null,
        "mileage": 0.0
      }]
    }
  },
  methods: {
    //加载数据
    loadPath() {
      window.addEventListener('message', (e) => {
        try {
          //地图初始化再加载数据
          if (JSON.parse(e.data).action == 'initComplete') {
            document.getElementById('mapIframe').contentWindow.postMessage(JSON.stringify(this.data), "*")
          }
        } catch (error) { }
      })
    }
  },
  created() {

  },
  mounted() {
    let arr = this.arr.map(item => {
      // item.lng = Number(item.lng) / 600000
      // item.lat = Number(item.lat) / 600000
      item.lng = Number(item.lng)
      item.lat = Number(item.lat)
      item.speed = item.gpsSpeed
      item.time = item.gpsTime
      return item
    })
    console.log(arr)
    this.data = {
      action: "drawTrack",
      data: {
        vno: "京A12345",
        trackData: arr
        // trackData: [{ "lng": 113.704375, "lat": 22.8203867, "time": 1498468172000, "speed": 60, "mileage": 0 },
        // { "lng": 114.704375, "lat": 22.82038, "time": 1498468202000, "speed": 60, "mileage": 0.7458405882190926 },
        // { "lng": 115.704375, "lat": 22.820385, "time": 1498468232000, "speed": 60, "mileage": 1.3024380426159599 },
        // { "lng": 116.704375, "lat": 22.8203833, "time": 1498468262000, "speed": 60, "mileage": 1.4916811775003584 },
        // { "lng": 117.704375, "lat": 22.8203833, "time": 1498468292000, "speed": 60, "mileage": 1.4916811775003584 },
        // { "lng": 118.704375, "lat": 22.8203817, "time": 1498468322000, "speed": 60, "mileage": 1.6697923628365445 }]
      }
    }
    this.loadPath()
  },
  watch: {
    path(val) {
      if (val.length != 0) {
        // this.data = {
        //   action: "drawTrack",
        //   data: {
        //     vno: "京A12345",
        //     trackData: [{ "lng": 113.704375, "lat": 22.8203867, "time": 1498468172000, "speed": 60, "mileage": 0 },
        //     { "lng": 114.704375, "lat": 22.82038, "time": 1498468202000, "speed": 60, "mileage": 0.7458405882190926 },
        //     { "lng": 115.704375, "lat": 22.820385, "time": 1498468232000, "speed": 60, "mileage": 1.3024380426159599 },
        //     { "lng": 116.704375, "lat": 22.8203833, "time": 1498468262000, "speed": 60, "mileage": 1.4916811775003584 },
        //     { "lng": 117.704375, "lat": 22.8203833, "time": 1498468292000, "speed": 60, "mileage": 1.4916811775003584 },
        //     { "lng": 118.704375, "lat": 22.8203817, "time": 1498468322000, "speed": 60, "mileage": 1.6697923628365445 }]
        //   }
        // }
      }
    }
  },
}
</script>

<style>
</style>